var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sub-header',{attrs:{"backButton":true}}),_c('div',{staticClass:"flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar mx-6 min-h-full"},[(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center flex-1 bg-white py-16 rounded-md flex-col"},[_c('Loader',{staticClass:"my-5",attrs:{"size":8,"loader":_vm.loading}}),_vm._v(" Loading")],1):_c('div',[(_vm.userDetails)?_c('div',{staticClass:"bg-white rounded-sm p-6 flex flex-col gap-5"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"start"}},[_c('div',{staticClass:"flex gap-6 flex-col flex-1"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" User Name ")]),_c('neo-input',{staticClass:"rounded-lg h-10 w-2/6",staticStyle:{"border":"1px solid #e8e8e8"},attrs:{"placeholder":"Group Name","bg":"white"},model:{value:(_vm.userDetails.user_name),callback:function ($$v) {_vm.$set(_vm.userDetails, "user_name", $$v)},expression:"userDetails.user_name"}})],1),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" Email ")]),_c('neo-input',{staticClass:"rounded-lg h-10 w-2/6",staticStyle:{"border":"1px solid #e8e8e8"},attrs:{"disabled":"","placeholder":"Assigned Client","bg":"white"},model:{value:(_vm.userDetails.email),callback:function ($$v) {_vm.$set(_vm.userDetails, "email", $$v)},expression:"userDetails.email"}})],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"8px","width":"33.33%"}},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" Assigned Roles ")]),_c('vue-multiselect',{attrs:{"multiple":true,"config":{ limit: 2 },"placeholder":"Select a Roles to add","show-labels":false,"maxHeight":200,"options":_vm.roleOptions,"track-by":"id","label":"title","loading":_vm.loading,"colors":{
                                text: '#282828',
                                bg: 'white',
                                svg: '#282828',
                                border: '#e8e8e8',
                            },"value":_vm.roleValue},model:{value:(_vm.roleValue),callback:function ($$v) {_vm.roleValue=$$v},expression:"roleValue"}})],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"8px","width":"33.33%"}},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" Assigned Groups ")]),_c('vue-multiselect',{attrs:{"multiple":true,"config":{ limit: 2 },"placeholder":"Select a Groups to add","show-labels":false,"options":_vm.groupOptions,"track-by":"id","label":"title","loading":_vm.loading,"colors":{
                                text: '#282828',
                                bg: 'white',
                                svg: '#282828',
                                border: '#e8e8e8',
                            },"value":_vm.groupValue},model:{value:(_vm.groupValue),callback:function ($$v) {_vm.groupValue=$$v},expression:"groupValue"}})],1)]),_c('div',{staticStyle:{"height":"100%"}},[_c('button',{staticClass:"flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm",on:{"click":_vm.deleteUser}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)])]),_c('div',{staticClass:"mt-4 flex justify-end max-w-full gap-4"},[_c('neo-button',{attrs:{"text":"Save","type":"primary"},on:{"click":_vm.updateUser}})],1)]):_c('div',{staticClass:"bg-white rounded-sm p-6 flex flex-col gap-5 text-center"},[_vm._v("User not found")])])]),_c('modal-confirm',{ref:"confirm-popup",attrs:{"title":"Are you sure?","message":"Please confirm you're about to delete the field."}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }