<template>
    <div>
        <sub-header :backButton="true" />
        <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar mx-6 min-h-full">
            <div class="flex items-center justify-center flex-1 bg-white py-16 rounded-md flex-col" v-if="loading"><Loader class="my-5" :size="8" :loader="loading" /> Loading</div>
            <div v-else>
                <div v-if="userDetails" class="bg-white rounded-sm p-6 flex flex-col gap-5">
                    <div style="display: flex; justify-content: space-between; align-items: start">
                        <div class="flex gap-6 flex-col flex-1">
                            <div class="flex flex-col gap-2">
                                <span class="text-gray-500 text-md"> User Name </span>
                                <neo-input style="border: 1px solid #e8e8e8" class="rounded-lg h-10 w-2/6" placeholder="Group Name" bg="white" v-model="userDetails.user_name"> </neo-input>
                            </div>
                            <div class="flex flex-col gap-2">
                                <span class="text-gray-500 text-md"> Email </span>
                                <neo-input disabled style="border: 1px solid #e8e8e8" class="rounded-lg h-10 w-2/6" placeholder="Assigned Client" bg="white" v-model="userDetails.email"> </neo-input>
                            </div>
                            <div style="display: flex; flex-direction: column; gap: 8px; width: 33.33%">
                                <span class="text-gray-500 text-md"> Assigned Roles </span>
                                <vue-multiselect
                                :multiple="true"
                                :config="{ limit: 2 }"
                                placeholder="Select a Roles to add"
                                :show-labels="false"
                                :maxHeight="200"
                                :options="roleOptions"
                                track-by="id"
                                label="title"
                                v-model="roleValue"
                                :loading="loading"
                                :colors="{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#e8e8e8',
                                }"
                                :value="roleValue"
                            />
                            </div>
                            <div style="display: flex; flex-direction: column; gap: 8px; width: 33.33%">
                                <span class="text-gray-500 text-md"> Assigned Groups </span>

                                <vue-multiselect
                                :multiple="true"
                                :config="{ limit: 2 }"
                                placeholder="Select a Groups to add"
                                :show-labels="false"
                                :options="groupOptions"
                                track-by="id"
                                label="title"
                                v-model="groupValue"
                                :loading="loading"
                                :colors="{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#e8e8e8',
                                }"
                                :value="groupValue"
                            />
                            </div>
                        </div>
                        <div style="height: 100%">
                            <button @click="deleteUser" class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm">
                                <font-awesome-icon icon="trash" />
                            </button>
                        </div>
                    </div>

                    <div class="mt-4 flex justify-end max-w-full gap-4">
                        <neo-button text="Save" @click="updateUser" type="primary" />
                    </div>
                </div>
                <div v-else class="bg-white rounded-sm p-6 flex flex-col gap-5 text-center">User not found</div>
            </div>
        </div>

        <!-- delete popup confirm -->

        <modal-confirm title="Are you sure?" message="Please confirm you're about to delete the field." ref="confirm-popup"> </modal-confirm>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import Button from "@/components/button";
import axios from "@/axios";
import Loader from "@/components/loader";
// import Multiselect from "@/components/vue-multiselect";
import Multiselect from "@/components/dropdown-base";
const modalConfirm = () => import("@/components/modal-confirm");

export default {
    name: "field-table-data-view",
    components: {
        SubHeader,
        "neo-input": Input,
        "neo-button": Button,
        "vue-multiselect": Multiselect,
        modalConfirm,
        Loader,
    },
    props: {},
    data: () => ({
        roleValue: [],
        groupValue: [],
        roleOptions: [],
        groupOptions: [],
        loading: false,
        userDetails: null,
        client_id: null,
        user_id: null,
    }),
    async mounted() {
        this.loading = true;
        if (!this.$store.getters.getClientId) {
            await this.$store.dispatch("fetchClientId");
        }
        this.user_id = this.$route.params.id;
        this.client_id = this.$store.getters.getClientId;
        await this.fetchUserDetails();
    },
    methods: {
        async fetchUserGroups() {
            let { data } = await axios.get(`group/client/${this.client_id}`);
            console.log(data, "group options");
            this.groupOptions = data;
        },
        async fetchUserRoles() {
            let { data } = await axios.get(`role/client/${this.client_id}`);
            this.roleOptions = data;
        },
        async fetchUserDetails() {
            await this.fetchUserGroups();
            await this.fetchUserRoles();
            this.loading = true;
            const url = `/user/client/${this.client_id}/user/${this.user_id}`;
            try {
                const { data } = await axios.get(url);
                console.log(this.roleOptions, "Role Options");
                this.roleOptions?.forEach((el) => {
                    data?.role_ids?.forEach((role) => {
                        if (role.role_id === el.id) {
                            this.roleValue.push(el);
                        }
                    });
                });
                this.groupOptions?.forEach((el) => {
                    data?.group_ids?.forEach((role) => {
                        if (role.group_id === el.id) {
                            console.log(role.id, el.group_id, "asdnajsnd");
                            this.groupValue.push(el);
                        }
                    });
                });
                this.userDetails = data;
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "User Details not found");
            }
            this.loading = false;
        },
        async updateUser() {
            const group_ids = this.groupValue.map((x) => ({ group_id: x.id }));
            const role_ids = this.roleValue.map((x) => ({ role_id: x.id }));
            try {
                let { data } = await axios.put("user/client/client", {
                    id: this.userDetails.id,
                    email: this.userDetails.email,
                    user_name: this.userDetails.user_name,
                    group_ids: group_ids,
                    role_ids: role_ids,
                });
                this.$toast.success(data.message || "user updated");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update user");
                console.log("error", error);
            }
        },
        async deleteUser() {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This user  will be deleted permanently. Please confirm to continue deleting this user.",
            });
            if (promise) {
                try {
                    console.log("delete user api called on id:", this.user_id);
                    let url =`user/${this.user_id}/client`;
                    let payload = {
                        client_id : this.clientId
                    }

                    let {data} = await axios.delete(url,{
                        data: payload
                    });
                    this.$toast.success(data.message || "User Deleted");
                    this.$router.back();
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to delete user deleted");
                    console.log("error", error);
                }
            }
            this.$refs["confirm-popup"].close();
        },
    },
};
</script>
<style scoped lang="scss"></style>
